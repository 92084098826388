import { createI18n } from "vue-i18n"
import Locale from "./vue-i18n-locales.generated.js"
import { get } from 'lodash';

const i18n = createI18n({
  locale: document.documentElement.lang.substring(0, 2),
  globalInjection: true,
  allowComposition: true,
  messages: Locale,
  silentTranslationWarn: true,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  // Escape '@' character: https://github.com/intlify/vue-i18n/issues/118
  // Without this, SyntaxError(10) errors are logged to Sentry when the key has @ character in it.
  // See also: https://vue-i18n.intlify.dev/guide/essentials/syntax.html#special-characters
  messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
})

export default i18n
