import axios from "axios"
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
const httpClient = axios.create()

// Handle expected errors.
httpClient.interceptors.response.use(
  response => response,
  error => {
    // If the session has expired, reload the page.
    if (error.response?.status === 401) {
      // Check if the request is made to the same subdomain
      if (window.location.hostname === error.response.config.url.split('/')[2]) {
        window.location.reload()
        // Resolve the promise so that it won't log any error or sent to Sentry.
        return Promise.resolve()
      }
    }

    // Ignore mixpanel 419 (CSRF token) errors as they are unlikely to happen in production.
    // It would happen if the developer deletes the session cookies to test the 401 issue above.
    if (error.response?.status === 419 && error.response.config.url.match('mixpanel')) {
      // Resolve the promise so that it won't log any error or sent to Sentry.
      return Promise.resolve()
    }

    return Promise.reject(error)
  }
)

export default httpClient
